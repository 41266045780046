<template>
    <div class="flex justify-center">
        <div class="card-tail w-[550px]" v-loading="$waiting.is('loading')">
            <div class="border-b mb-3 pb-2">
                <p>Redigera</p>
            </div>
            <el-form :model="itemData" class="p-8" label-position="left" label-width="200px">
                <el-form-item prop="person" label="Person">
                    <el-input v-model="itemData.person" disabled />
                </el-form-item>
                <el-form-item prop="civicNumber" label="Civic nummer">
                    <el-input v-model="itemData.civicNumber" disabled />
                </el-form-item>
                <el-form-item prop="hasK10Pdf" label="Har PDF">
                    <div class="flex">
                        <el-button type="primary" @click="showPdf" :disabled="!itemData.hasK10Pdf">Visa</el-button>
                    </div>
                </el-form-item>
                <el-form-item prop="wantHelp" label="Vill ha hjälp">
                    <el-input v-model="itemData.wantHelp" disabled />
                </el-form-item>
                <el-form-item prop="status" label="Status">
                    <el-select v-model="itemData.status" class="w-full">
                        <el-option :value="0" label="Skapad" />
                        <el-option :value="1" label="Utkast" />
                        <el-option :value="2" label="Inskickad" />
                        <el-option :value="3" label="Behandlas" />
                        <el-option :value="4" label="Klar" />
                        <el-option :value="5" label="Fakturerad" />
                        <el-option :value="6" label="Ingen hjälp" />
                        <el-option :value="7" label="Ej genomförbar" />
                    </el-select>
                </el-form-item>
                <el-form-item prop="companyName" label="Företagsnamn">
                    <el-input v-model="itemData.companyName" />
                </el-form-item>
                <el-form-item prop="registrationNumber" label="Registreringsnummer">
                    <el-input v-model="itemData.registrationNumber" />
                </el-form-item>
                <el-form-item prop="changeInStocks" label="Förändring i aktier">
                    <el-input type="textarea" rows="3" v-model="itemData.changeInStocks" />
                </el-form-item>
                <el-form-item prop="otherMessage" label="Annat meddelande">
                    <el-input type="textarea" rows="3" v-model="itemData.otherMessage" />
                </el-form-item>
            </el-form>
            <div class="flex justify-end mt-4 border-t pt-3">
                <el-button type="default" @click="$router.back()">Tillbaka</el-button>
                <el-button type="primary" @click="update" :loading="$waiting.is('updating')">Spara</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "./k10_declaration.api";

export default {
    data() {
        return {
            id: this.$route.params.id,
            itemData: {
                person: null,
                civicNumber: null,
                hasK10Pdf: null,
                wantHelp: null,
                status: 0,
                companyName: null,
                registrationNumber: null,
                changeInStocks: null,
                otherMessage: null,
            },
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "K10 Declaration detaljer");
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.$waiting.start("loading");
            this.itemData = await Api.getDetails(this.id);
            document.title = "K10 Declaration - " + this.itemData.companyName;
            this.$waiting.end("loading");
        },

        async update() {
            this.$waiting.start("updating");

            await Api.update(this.id, this.itemData);
            this.$notify.success({ title: "Utfört" });

            this.$waiting.end("updating");

            this.$router.back();
        },

        showPdf() {
            Api.showPdf(this.id);
        },
    },
};
</script>
