import Vue from "vue";

export default {
    getAll: async () => {
        try {
            const { data } = await Vue.axios.get(`k10-declaration`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: k10_declaration.api.js ~ getAll: ~ e", e);
            return [];
        }
    },

    getDetails: async id => {
        try {
            const { data } = await Vue.axios.get(`k10-declaration/` + id);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: k10_declaration.api.js ~ getDetails ~ e", e);
            return {};
        }
    },

    update: async (id, formData) => {
        try {
            await Vue.axios.put(`k10-declaration/` + id, {
                status: formData.status,
                companyName: formData.companyName,
                registrationNumber: formData.registrationNumber,
                changeInStocks: formData.changeInStocks,
                otherMessage: formData.otherMessage,
            });
        } catch (e) {
            console.log("🚀 ~ file: k10_declaration.api.js ~ update ~ e", e);
        }
    },

    showPdf: id => {
        var token = localStorage.getItem("token");
        var headers = "?token=" + token;
        var base = Vue.axios.defaults.baseURL;
        var url = `/k10-declaration/${id}/k10-pdf`;
        window.open(base + url + headers, `_blank`, `width=800, height=800`);
    },
};
